@font-face {
  font-family: "LibreFranklin";
  src: local("LibreFranklin"), url(./Fonts/LibreFranklin.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: "LibreFranklin", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: "Courier New", Courier, monospace;
}
