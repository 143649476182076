#timeLine {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  font-family: inherit;
  font-weight: bold;
  position: absolute;
  top: 5vh;
  left: 50%;
  transform: translateX(-50%);
}
.timePoint {
  width: 25vw;
  margin: 2.5vw;
  padding: 0.75rem;
  background: linear-gradient(to right, #3d68abff, transparent 10%);
  background-position: 0vw;
  border-radius: 0.75rem;
  opacity: 0.25;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  font-size: calc(10px + 2.5vmin);
  font-family: inherit;
}
.timePoint > img {
  width: 1.5rem;
}
.active {
  background: linear-gradient(to right, transparent, #3d68abff);
  color: white;
  background-position: 26.55vw;
  opacity: 1;
}
