.content {
  width: 50vw;
  height: 60vh;
  position: absolute;
  top: 11rem;
  right: 10rem;
  transition: all 0.5s ease-in-out;
}
.dimIcons {
  width: 20vw;
  position: absolute;
  opacity: 0.1;
  transition: all 0.5s ease-in-out;
}
.planta {
  top: 0;
  left: 0;
}
.pedir {
  top: 33%;
  left: 33%;
}
.vedest {
  bottom: 0;
  right: 0;
}
.calculated {
  opacity: 1;
}

.sw {
  visibility: inherit;
  opacity: 1;
}
.hd {
  visibility: hidden;
  opacity: 0;
}
.content {
  font-size: calc(10px + 0.75vmin);
  font-family: inherit;
  text-align: left;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
#contentCustos::-webkit-scrollbar {
  display: none;
}
.field {
  background: linear-gradient(to right, transparent, #3d68abdd);
  color: white;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.destaque {
  font-size: calc(10px + 1vmin);
  font-family: inherit;
}
p {
  width: 33%;
}
.lw {
  color: #3d68ab;
  font-weight: 550;
}
.sistVed {
  color: #9b0b42;
  font-weight: 500;
}
.disabled {
  opacity: 0.33;
}
#detalhar {
  background-color: #3d68ab;
  border-radius: 1rem;
  color: white;
  padding: 1rem;
  margin: 1rem 0;
  font-size: calc(10px + 0.5vmin);
  font-family: inherit;
  cursor: pointer;
  border: none;
  transition: all 0.25s ease-in-out;
}
#detalhar.detalhado {
  background-color: #3d68ab88;
}
#detalhe.hidden {
  height: 0;
}
#detalhe.visible {
  height: auto;
}
#detalhe {
  transition: all 0.25s ease-in-out;
}
.economia {
  color: #88dd18;
  font-weight: bold;
  font-family: inherit;
}

.bom {
  color: green;
  font-weight: bold;
  font-family: inherit;
}
.medio {
  color: #7c7219;
  font-weight: bold;
  font-family: inherit;
}
.ruim {
  color: #9b0b0b;
  font-weight: bold;
  font-family: inherit;
}
