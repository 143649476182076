.tab {
  background-color: white;
  border-radius: 1rem;
  width: 30vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
  z-index: 0;
}
.current {
  opacity: 1;
  transform: scale(105%);
  z-index: 1;
}
#dimensoes {
  left: 3vw;
  top: 22vh;
}
#custos {
  left: 3vw;
  top: 22vh;
}
#comparativo {
  left: 3vw;
  top: 22vh;
}

select {
  width: 45%;
  margin: 0 0.5rem;
  text-align: center;
  font-size: calc(10px + 0.75vmin);
  font-family: inherit;
}
.vedInput > img {
  max-width: 45%;
  max-height: 7.5rem;
  margin: 1rem;
}
input[type="number"] {
  width: 20vw;
  text-align: right;
}
select,
input[type="number"] {
  margin-top: 0.5rem;
  background: none;
  padding: 0.5rem 0.25rem;
  border: none;
  border-bottom: 1px solid lightgray;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: 3rem;
  margin-left: 1rem;
}
select:focus,
input:focus {
  outline: none;
}

.vedInput {
  margin: 2rem;
  font-size: calc(10px + 1vmin);
  font-family: inherit;
  transition: all 0.5s ease-in-out;
}
.vedInput:focus-within {
  transform: scale(110%);
}

.calculated {
  transform: scale(110%);
  font-weight: bold;
  font-family: inherit;
}

.obs {
  text-align: left;
  font-size: 0.75rem;
  font-family: inherit;
}
