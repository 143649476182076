.slide {
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translateX(-50%);
  width: 70vw;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  border-radius: 1rem;
}
.current {
    opacity: 1;
}
.control {
  top: 42.5%;
  color: #3d68ab88;
  font-weight: bolder;
  font-size: calc(10px + 5vmin);
  cursor: pointer;
  font-family: inherit;
}
.left {
  position: absolute;
  left: 10%;
}
.right {
  position: absolute;
  right: 10%;
}
