.App {
  text-align: center;
}

.App-header {
  background: url(./Images/fundo.jpg);
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #1e1e1cff;
}

.shown {
  visibility: visible;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

#envelope > img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  transition: all 1s ease-in-out;
}
#envelope.home > img {
  bottom: 0;
  right: 0;
}
#envelope.nohome > img {
  bottom: 90vh;
  right: 30vw;
}

#logo > img {
  height: 11vh;
  position: absolute;
  bottom: 5vh;
  cursor: pointer;
  transition: all 1s ease-in-out;
}
#logo.home > img {
  right: 5vh;
}
#logo.nohome > img {
  right: 50%;
  transform: translateX(50%);
}

#homeButton {
  width: 25vh;
  height: 25vh;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  top: 42vh;
  left: 35.75vw;
  cursor: pointer;
}
@media (prefers-reduced-motion: no-preference) {
  #homeButton {
    animation: homeButton infinite 0.75s linear;
  }
}
@keyframes homeButton {
  from {
    transform: scale(50%);
    opacity: 0.5;
  }
  to {
    transform: scale(100%);
    opacity: 0.25;
  }
}

#mainButtons {
  position: absolute;
  top: 50vh;
  transform: translateY(-50%);
}
.mainButton {
  width: 30vw;
  height: 20vw;
  margin: 5vw;
  border-radius: 2rem;
  border: none;
  color: white;
  font-family: inherit;
  font-size: larger;
  font-weight: bolder;
  background-color: #3d68abff;
  cursor: pointer;
  box-shadow: 0.5rem 0.5rem 0.75rem #1e1e1c44;
}
